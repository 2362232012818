import { ChangeDetectionStrategy, Component, EventEmitter, inject, input, Output } from '@angular/core';
import { NotificationMainProjectOwnerAccepted, SvgIconDirective } from 'ngx-q360-lib';
import { getNotificationDate } from '@app/shared/notifications-shared/components/notifications/notifications.utils';
import { MarkNotificationAsRead } from '@app/store/notification/notification.actions';
import { Store } from '@ngxs/store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectListMenuComponent } from '@project/project-list-menu/project-list-menu.component';
import { GetMainProject } from '@app/store/project/project.actions';
import { MatTooltip } from '@angular/material/tooltip';

import { FlexModule } from '@angular/flex-layout/flex';

@Component({
  selector: 'app-main-project-owner-invite-accepted-notification',
  templateUrl: './main-project-owner-invite-accepted-notification.component.html',
  styleUrls: ['../../notification/notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, MatTooltip, SvgIconDirective],
})
export class MainProjectOwnerInviteAcceptedNotificationComponent {
  protected readonly getDate = getNotificationDate;
  private store = inject(Store);
  private matDialog = inject(MatDialog);

  notification = input.required<NotificationMainProjectOwnerAccepted>();
  popupMode = input<boolean>(false);
  unseenCount = input(0);

  @Output() deleteNotification: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickNotification: EventEmitter<void> = new EventEmitter<void>();

  onDeleteNotification(event: any): void {
    event.stopPropagation();
    this.deleteNotification.emit(this.notification().id);
  }

  markAsRead(): void {
    this.clickNotification.emit();
    this.store.dispatch(new MarkNotificationAsRead(this.notification()));
    this.openCreateProjectDialog();
    this.store.dispatch(new GetMainProject(this.notification().payload.mainProject.id));
  }

  openCreateProjectDialog(): void {
    const config: MatDialogConfig = {
      position: {
        top: '56px',
      },
      panelClass: 'project-list-menu-component',
      width: '100%',
      maxWidth: '1408px',
      minHeight: '350px',
      data: {
        mainProjectId: this.notification().payload.mainProject.id,
      },
    };
    const dialogRef = this.matDialog.open(ProjectListMenuComponent, config);
    dialogRef.componentInstance.config = config;
  }
}
