@if (!popupMode()) {
  <div class="p-16 cursor-pointer flex-column greyscale-background-grey-bg">
    <div class="flex-column" flexGap="4px">
      <div class="labels-label-s greyscale-light-text">{{ getDate(notification().createdAt) }}</div>
      <div class="labels-label-l greyscale-darkest-grey">
        You’ve been invited to be the
        {{ notification().notificationType === notificationType.MainProjectOwnerInvited ? 'owner' : 'co-owner' }} of the
        main project group <strong>{{ notification().payload.mainProject.name }}</strong
        >.
      </div>
      <div>
        <mat-accordion class="plain-accordion">
          <mat-expansion-panel (opened)="panelExpanded.set(true)" (closed)="panelExpanded.set(false)">
            <mat-expansion-panel-header>
              <mat-panel-title class="p-0">
                <div class="flex-column">
                  <div class="labels-label-m primary-colors-accent-blue">
                    @if (panelExpanded()) {
                      Hide details
                    } @else {
                      Show details
                    }
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-column my-20" flexGap="24px">
              @if (notification().notificationType === notificationType.MainProjectOwnerInvited) {
                <div class="flex-row labels-label-m greyscale-light-text" flexLayoutAlign="start center" flexGap="8px">
                  <svg class="svg-16" svgIcon="framed-info" />
                  {{ notification().payload.organizationName }} currently co-owns this main project group.
                </div>
              }
              <div class="flex-column">
                <div class="labels-label-s greyscale-light-text">Invitation by</div>
                <div class="labels-label-l greyscale-darkest-grey">{{ notification().payload.userName }}</div>
                <div class="labels-label-s greyscale-light-text">{{ notification().payload.organizationName }}</div>
              </div>
              @if (notification().payload.message) {
                <div class="flex-column">
                  <div class="labels-label-s greyscale-light-text">Message</div>
                  <div class="labels-label-l greyscale-darkest-grey">{{ notification().payload.message }}</div>
                </div>
              }
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="flex-row" flexLayoutAlign="end center" flexGap="16px">
          <button class="tertiary-button has-icon" (click)="onRejectInvitation()">
            Reject
            <svg svgIcon="basic-close" />
          </button>
          <button class="primary-button has-icon" (click)="onAcceptInvitation()">
            Accept
            <svg svgIcon="basic-checkmark" />
          </button>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="cursor-pointer flex-row" flexGap="20px" (click)="markAsRead()">
    <div class="labels-label-l greyscale-darkest-grey">
      You’ve been invited to be the
      {{ notification().notificationType === notificationType.MainProjectOwnerInvited ? 'owner' : 'co-owner' }} of the
      main project group <strong>{{ notification().payload.mainProject.name }}</strong
      >.
    </div>
    <svg
      matTooltip="Clear"
      matTooltipClass="icon-tooltip"
      class="svg-24"
      svgIcon="basic-close"
      (click)="onDeleteNotification($event)"
    />
  </div>
}
