import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { FlexGapDirective, FlexLayoutAlignDirective, NotificationTaskUpdated, SvgIconDirective } from 'ngx-q360-lib';
import { MarkNotificationAsRead } from '@app/store/notification/notification.actions';
import {
  getNotificationDate,
  getProjectTaskUpdatedFieldInfo,
} from '@app/shared/notifications-shared/components/notifications/notifications.utils';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-task-updated-notification',
  templateUrl: './task-updated-notification.component.html',
  styleUrls: ['../../notification/notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexGapDirective, FlexLayoutAlignDirective, MatTooltip, SvgIconDirective],
})
export class TaskUpdatedNotificationComponent {
  protected readonly getDate = getNotificationDate;
  private store = inject(Store);
  private router = inject(Router);

  notification = input.required<NotificationTaskUpdated>();
  popupMode = input<boolean>(false);
  grouped = input(false);
  unseenCount = input(0);
  showAllDetails = input(true);

  @Output() deleteNotification: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickNotification: EventEmitter<void> = new EventEmitter<void>();

  notifInfo = computed(() => getProjectTaskUpdatedFieldInfo(this.notification()));

  openTask(): void {
    this.store.dispatch(new MarkNotificationAsRead(this.notification()));
    void this.router.navigate([
      `/pages/project/${this.notification().payload.workItem.projectId}/tasks-kan-ban/${this.notification().payload.workItem.id}/fullscreen`,
    ]);
    this.clickNotification.emit();
  }

  onDeleteNotification(event: any): void {
    event.stopPropagation();
    this.deleteNotification.emit(this.notification().id);
  }
}
