@if (!popupMode()) {
  <div class="p-16 flex-column" flexGap="12px" (click)="openProject()">
    <div class="flex-row" flexLayoutAlign="start center" flexGap="20px">
      <svg class="svg-24 greyscale-darkest-grey" svgIcon="other-company" />
      <div class="flex">
        <div class="mb-4 flex-row" flexLayoutAlign="start center">
          <div class="labels-label-s greyscale-light-text">{{ getDate(notification().createdAt) }}</div>
        </div>
        <div class="mb-4 flex-row" flexLayoutAlign="start center" flexGap="8px">
          <div class="labels-label-s greyscale-light-text">
            {{ notification().payload.project.mainProject.code }}-<span class="labels-label-s greyscale-darkest-grey">{{
              notification().payload.project.code
            }}</span>
          </div>
        </div>
        <div class="flex-row">
          <div class="labels-label-l greyscale-darkest-grey">
            <strong>{{ notification().payload.source.name }}</strong> assigned you a position on a project
            <strong>{{ notification().payload.project.name }}</strong
            >.
          </div>
        </div>
      </div>
      <div class="flex-column" flexGap="10px">
        @if (!notification().seen) {
          <div flexLayoutAlign="start center" class="not-seen mx-8"></div>
        }
        @if (unseenCount() > 0) {
          <div flexLayoutAlign="start center" class="not-seen-count mx-8">
            {{ unseenCount() }}
          </div>
        }
      </div>
    </div>
  </div>
} @else {
  <div class="cursor-pointer flex-row" flexGap="20px" (click)="openProject()">
    <svg class="svg-24 greyscale-darkest-grey" svgIcon="other-company" />
    <div class="flex-row">
      <div class="labels-label-l greyscale-darkest-grey flex">
        <strong>{{ notification().payload.source.name }}</strong> assigned you a position on a project
        <strong>{{ notification().payload.project.name }}</strong
        >.
      </div>
    </div>
    <svg
      matTooltip="Clear"
      matTooltipClass="icon-tooltip"
      class="svg-24"
      svgIcon="basic-close"
      (click)="onDeleteNotification($event)"
    />
  </div>
}
