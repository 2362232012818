@if (!popupMode()) {
  <div class="p-16 flex-column" flexGap="12px" (click)="openSupportItem()">
    <div class="flex-row" flexLayoutAlign="start center" flexGap="20px">
      <svg class="svg-24 greyscale-darkest-grey" svgIcon="other-company" />
      <div fxFlex>
        <div class="mb-4 flex-row" flexLayoutAlign="start center">
          <div class="labels-label-s greyscale-light-text">{{ getDate(notification().createdAt) }}</div>
        </div>
        <div class="mb-4 flex-row" fxLayoutAlign="start center" flexGap="8px">
          <div class="labels-label-s greyscale-light-text">
            {{ notification().payload.organization.name }}
          </div>
        </div>
        <div class="flex-row">
          <div class="labels-label-l greyscale-darkest-grey">
            <strong>{{ notification().payload.source.name }}</strong> has sent you a support request
            @if (notification().payload.project) {
              <strong> on a project {{ notification().payload.project.name }}</strong>
            }
            .
          </div>
        </div>
      </div>
      <div class="flex-column" flexGap="10px">
        @if (!notification().seen) {
          <div flexLayoutAlign="center center" class="not-seen mx-8"></div>
        }
      </div>
    </div>
  </div>
} @else {
  <div class="cursor-pointer flex-column" flexGap="20px" (click)="openSupportItem()">
    <svg class="svg-24 greyscale-darkest-grey" svgIcon="other-company" />
    <div class="flex">
      <div class="mb-8 flex-row" flexLayoutAlign="start center" flexGap="8px">
        <div class="labels-label-s greyscale-darkest-grey">{{ notification().payload.organization.name }}</div>
      </div>
      <div class="flex-row">
        <div fxFlex class="labels-label-l greyscale-darkest-grey">
          <strong>{{ notification().payload.source.name }}</strong> has sent you a support request
          @if (notification().payload.project) {
            <strong> on a project {{ notification().payload.project.name }}</strong>
          }
          .
        </div>
      </div>
    </div>
    <svg
      matTooltip="Clear"
      matTooltipClass="icon-tooltip"
      class="svg-24"
      svgIcon="basic-close"
      (click)="onDeleteNotification($event)"
    />
  </div>
}
