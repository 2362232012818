import { ChangeDetectionStrategy, Component, EventEmitter, inject, input, Output } from '@angular/core';
import {
  FlexGapDirective,
  FlexLayoutAlignDirective,
  NotificationAssignedPosition,
  SvgIconDirective,
} from 'ngx-q360-lib';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MarkNotificationAsRead } from '@app/store/notification/notification.actions';
import { getNotificationDate } from '@app/shared/notifications-shared/components/notifications/notifications.utils';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-assigned-position-notification',
  templateUrl: './assigned-position-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexGapDirective, FlexLayoutAlignDirective, MatTooltip, SvgIconDirective],
})
export class AssignedPositionNotificationComponent {
  protected readonly getDate = getNotificationDate;
  private router = inject(Router);
  private store = inject(Store);

  notification = input.required<NotificationAssignedPosition>();
  popupMode = input<boolean>(false);
  unseenCount = input(0);

  @Output() deleteNotification: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickNotification: EventEmitter<void> = new EventEmitter<void>();

  openProject(): void {
    this.store.dispatch(new MarkNotificationAsRead(this.notification()));
    void this.router.navigate([`/pages/project/${this.notification().payload.project.id}`]);
    this.clickNotification.emit();
  }

  onDeleteNotification(event: any): void {
    event.stopPropagation();
    this.store.dispatch(new MarkNotificationAsRead(this.notification()));
    this.deleteNotification.emit(this.notification().id);
  }
}
