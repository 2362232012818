import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationDecisionMaker, SvgIconDirective } from 'ngx-q360-lib';
import { Store } from '@ngxs/store';
import { MarkNotificationAsRead } from '@app/store/notification/notification.actions';
import { getNotificationDate } from '@app/shared/notifications-shared/components/notifications/notifications.utils';
import { MatTooltip } from '@angular/material/tooltip';

import { FlexModule } from '@angular/flex-layout/flex';

@Component({
  selector: 'app-decision-maker-notification',
  templateUrl: './decision-maker-notification.component.html',
  styleUrls: ['../../notification/notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, MatTooltip, SvgIconDirective],
})
export class DecisionMakerNotificationComponent {
  protected readonly getDate = getNotificationDate;
  private router = inject(Router);
  private store = inject(Store);

  notification = input.required<NotificationDecisionMaker>();
  workItem = computed(() => {
    return this.notification().payload.workItem
      ? this.notification().payload.workItem
      : this.notification().payload.decision;
  });
  popupMode = input<boolean>(false);
  unseenCount = input(0);
  showAllDetails = input(true);

  @Output() deleteNotification: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickNotification: EventEmitter<void> = new EventEmitter<void>();

  openDecision(): void {
    this.store.dispatch(new MarkNotificationAsRead(this.notification()));
    void this.router.navigate([
      `/pages/project/${this.workItem()?.projectId}/decisions-kan-ban/${this.workItem()?.id}/fullscreen`,
    ]);
    this.clickNotification.emit();
  }

  onDeleteNotification(event: any): void {
    event.stopPropagation();
    this.deleteNotification.emit(this.notification().id);
  }
}
